import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
// import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用外功增益: 技能增益列表类型[] = [
  // ...获取快照增益(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 236 / 1024 }],
  },
  // 套装双会特效
  {
    // 18176
    增益名称: '祝祷',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    // 增益类型: '全局启用', // 等考拉支持了再删
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  // {
  //   增益名称: '枭神_快照',
  //   增益所在位置: '技能',
  //   // 增益类型: '部分启用',
  //   增益类型: '部分启用',
  //   增益集合: [
  //     { 属性: 属性类型.内功会心百分比, 值: 0.1 },
  //     { 属性: 属性类型.郭氏内功会心效果等级, 值: 100 },
  //   ],
  // },
]

export default 通用外功增益
