// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sL12hm5PhdIUmF1mRpIA {
  position: absolute;
  left: 62px;
  top: 156px;
  text-align: center;
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  color: #fff;
}
.f0BiVnVmK1jS0c6odlYt {
  margin: 5px;
  width: 102px;
  height: 57px;
  text-align: center;
  padding-top: 10px;
}
.XJ9LVAUNJc9HXOt04I4d {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 6px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
.P2X0GNfbQEpqOESWBIaZ {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
.loRlgC18U6BjoVIkxOTT {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/属性区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EAEA,iBAAA;AADF;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,uCAAA;AAHF;AAOA;EAGE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,uCAAA;AAPF;AAWA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uCAAA;EACA,kBAAA;AATF","sourcesContent":[".attrWrap {\n  position: absolute;\n  left: 62px;\n  top: 156px;\n  text-align: center;\n  width: 500px;\n  display: flex;\n  flex-wrap: wrap;\n  margin: -5px;\n  color: #fff;\n}\n\n.item {\n  margin: 5px;\n  width: 102px;\n  height: 57px;\n  text-align: center;\n  // padding-top: 4px;\n  padding-top: 10px;\n}\n\n.name {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 14px;\n  // margin-bottom: 4px;\n  margin-bottom: 6px;\n  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);\n  // opacity: 0.85;\n}\n\n.title {\n  // font-size: 16px;\n  // line-height: 16px;\n  font-size: 18px;\n  line-height: 20px;\n  font-weight: 500;\n  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);\n  // margin-bottom: 2px;\n}\n\n.number {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 12px;\n  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attrWrap": `sL12hm5PhdIUmF1mRpIA`,
	"item": `f0BiVnVmK1jS0c6odlYt`,
	"name": `XJ9LVAUNJc9HXOt04I4d`,
	"title": `P2X0GNfbQEpqOESWBIaZ`,
	"number": `loRlgC18U6BjoVIkxOTT`
};
export default ___CSS_LOADER_EXPORT___;
