// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sPE3NotFhR_ZxAMJVQ0C {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/系统说明/index.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".label {\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `sPE3NotFhR_ZxAMJVQ0C`
};
export default ___CSS_LOADER_EXPORT___;
