// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keEhiaWoJRnHwc4qD0Wg {
  width: 100%;
  height: calc(100vh - 100px);
}
.LS0YVEnTskHC4m6x127t {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CZqvYzuvBRtrhl8f058E {
  display: flex;
  align-items: center;
}
.Z3iFZe8sc_jNMroiQrqb {
  font-size: 16px;
  margin: 0;
}
.qYDD_D9dKwSjIZ5m9EGA {
  color: #ff0000;
  font-size: 12px;
  margin-left: 12px;
}
.DHziZrUZNWqlKNbdFtwb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
}
.pKrfj4mY4hhCaG2nmJky {
  width: 150px;
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/系统工具/页面右下角工具/技改图表弹窗/index.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;AADF;AAIA;EACE,eAAA;EACA,SAAA;AAFF;AAKA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AAHF;AAMA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AAJF;AAOA;EACE,YAAA;EACA,kBAAA;AALF","sourcesContent":[".chart {\n  width: 100%;\n  height: calc(100vh - 100px);\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.name {\n  display: flex;\n  align-items: center;\n}\n\n.title {\n  font-size: 16px;\n  margin: 0;\n}\n\n.tip {\n  color: #ff0000;\n  font-size: 12px;\n  margin-left: 12px;\n}\n\n.content {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-right: 24px;\n}\n\n.select {\n  width: 150px;\n  margin-right: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `keEhiaWoJRnHwc4qD0Wg`,
	"header": `LS0YVEnTskHC4m6x127t`,
	"name": `CZqvYzuvBRtrhl8f058E`,
	"title": `Z3iFZe8sc_jNMroiQrqb`,
	"tip": `qYDD_D9dKwSjIZ5m9EGA`,
	"content": `DHziZrUZNWqlKNbdFtwb`,
	"select": `pKrfj4mY4hhCaG2nmJky`
};
export default ___CSS_LOADER_EXPORT___;
