/**
 * @name 心法模块-紫霞功
 * 模块写入者：蓝团
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 背景图 from './资源/背景图'
import 默认数据 from './默认数据'
import 技能系数, { 技能增益 } from './技能系数'
import 奇穴数据 from './奇穴'
import 计算循环 from './计算循环'

const 心法配置: 心法配置类型 = {
  名称: '紫霞功',
  模块负责人: '月慕青尘',
  // 内部测试: true,
  数据提供: '数据：考拉 蓝团',
  所属门派: '纯阳',
  简写: 'zxg',
  主属性: '根骨',
  功法: '内功',
  基础属性: { 基础攻击: 6518, 会心等级: 5527 },
  主属性额外加成: { 面板攻击: 1946 / 1024, 会心等级: 625 / 1024 },
  基础气血加成: 245, // Math.floor((14 * 1 + 10) / 100 * 1024) // 130级心法等级14
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  覆盖率: { 套装会心会效: 0.85 },
  系统配置: {
    主题色: 'hsl(205 80% 44% / 1)',
    心法图标: 'https://img.jx3box.com/image/xf/10014.png',
    背景图: 背景图,
    背景色渐变: `linear-gradient(to right, rgba(0,0,0,0.8) 5%, hsl(205 75% 44% / 0.3) 70%)`,
    // 背景色: 'rgba(168,88,157, 0.615)',
  },
}

export default 心法配置
