import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 断脉增益 from './技能增益/断脉'
import 绝脉增益 from './技能增益/绝脉'
import 骤风增益 from './技能增益/骤风'
import 截阳增益 from './技能增益/截阳'
import 引窍增益 from './技能增益/引窍'
import 破穴增益 from './技能增益/破穴'
import 劈风增益 from './技能增益/劈风'
import 劈风骤增益 from './技能增益/劈风骤'
import 抟风令增益 from './技能增益/抟风令'
import { 属性类型 } from '@/@types/属性'
import 通用外功增益 from './技能增益/通用增益/通用外功'
import 真实伤害系数 from '@/心法模块/统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '云合扇法',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: [
      ...通用外功增益,
      {
        增益名称: '普通攻击增伤',
        增益所在位置: '职业',
        增益类型: '全局启用',
        增益启用: true,
        增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
      },
    ],
  },
  {
    技能ID: 38093,
    技能名称: '破', // 38093
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.34 * (1 + 0.05 * 1) - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,52': {
        技能伤害系数: (i) => 获取破招实际系数(1048576 * (0.34 * (1 + 0.05 * i) - 1)),
      },
    },
  },
  {
    技能ID: 38084,
    技能名称: '断脉', // 38084
    技能伤害系数: 960 * 0.9,
    基础伤害_基础值: 20,
    技能增益列表: 断脉增益,
  },
  {
    技能ID: 38090,
    技能名称: '截阳', // 38090
    // 技能伤害系数: 1300 * 0.9 * 0.9 * 0.9 * 0.9,
    技能伤害系数: 852.9300000000001,
    基础伤害_基础值: 20,
    技能增益列表: 截阳增益,
  },
  {
    技能ID: 38438,
    技能名称: '引窍', // 38438
    统计名称: '引窍',
    基础伤害_基础值: 20,
    技能增益列表: 引窍增益,
    技能伤害系数: 2500,
    技能等级数据: {
      '1,52': {
        技能伤害系数: (i) =>
          1000 * (1 + 0.03 * (i - 1)) * 0.88 * 0.9 * 0.95 * 0.9 * 0.9 * 0.85 * 0.85,
        // 点奇穴以后系数变成
      },
    },
  },
  {
    技能ID: 38083,
    技能名称: '破穴', // 38083
    技能伤害系数: 720,
    基础伤害_基础值: 20,
    技能增益列表: 破穴增益,
  },
  {
    技能ID: 38531,
    技能名称: '封府', // 38531
    技能伤害系数: 240,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38554,
    技能名称: '朔风扬尘', // 38554
    统计名称: '朔风扬尘',
    技能伤害系数: 240,
    基础伤害_基础值: 1,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,8': {
        技能伤害系数: (i) => 240 * (1 + 0.5 * (i - 1)),
      },
    },
  },
  {
    技能ID: 38556,
    技能名称: '欺霜', // 38556
    统计名称: '欺霜',
    技能伤害系数: 260,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,5': {
        技能伤害系数: (i) => 220 + i * 40,
      },
    },
  },
  {
    技能ID: 38557,
    技能名称: '催烟', // 38557
    技能伤害系数: 300,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38085,
    技能名称: '绝脉', // 38085
    统计名称: '绝脉',
    基础伤害_基础值: 20,
    技能伤害系数: 80, // 80 * dwSkillLevel
    技能增益列表: 绝脉增益,
    技能等级数据: {
      '1,11': {
        技能伤害系数: (i) => 92 * i,
      },
    },
  },
  {
    技能ID: '37804_1',
    技能名称: '骤风', // 37804/1
    统计名称: '骤风',
    技能伤害系数: 235.98,
    基础伤害_基础值: 20,
    技能增益列表: 骤风增益,
  },
  {
    技能ID: '37804_2',
    技能名称: '横驱风靡', // 37804/2
    统计名称: '横驱风靡',
    技能伤害系数: 401.1659999999999,
    基础伤害_基础值: 0,
    技能增益列表: 骤风增益,
  },
  {
    技能ID: 38016,
    技能名称: '雾刃飞光·雾', // 38016
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 400 * 1.5,
    基础伤害_基础值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38075,
    技能名称: '雾刃飞光·刃', // 38075
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 440 * 1.5,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38076,
    技能名称: '雾刃飞光·飞', // 38076
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 480 * 1.5,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38077,
    技能名称: '雾刃飞光·光', // 38077
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 520 * 1.5,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 37816,
    技能名称: '劈风令', // 37816
    秘籍依赖技能: '劈风令',
    技能伤害系数: 1028.3129999999999,
    基础伤害_基础值: 20,
    技能增益列表: 劈风增益,
  },
  {
    技能ID: 38452,
    技能名称: '劈风令·骤', // 38452
    秘籍依赖技能: '劈风令',
    技能伤害系数: 393.82200000000006,
    基础伤害_基础值: 70,
    技能增益列表: 劈风骤增益,
  },
  {
    技能ID: 39340,
    技能名称: '纷飙', // 38452
    技能伤害系数: 880 * 0.8,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38447,
    技能名称: '抟风令', // 38447
    技能伤害系数: 480,
    基础伤害_基础值: 20,
    技能增益列表: 抟风令增益,
  },
  {
    技能ID: 38453,
    技能名称: '抟风令·爆', // 38453
    技能伤害系数: 512,
    基础伤害_基础值: 1220,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '39081',
    技能名称: '断脉·神兵',
    技能伤害系数: 65,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38590',
    技能名称: '胧雾观花',
    技能伤害系数: 720,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  断脉增益,
  骤风增益,
  绝脉增益,
}

export { 技能增益 }
