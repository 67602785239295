import React from 'react'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { Popover, Select } from 'antd'
import { 更新方案数据 } from '@/store/data'
import classnames from 'classnames'
import useCycle from '@/hooks/use-cycle'
import { 触发秒伤计算 } from '@/计算模块/计算函数'
import './index.css'
import { 根据循环判断快照计算列表 } from '@/计算模块/统一工具函数/增益计算函数'

function 循环选择() {
  const dispatch = useAppDispatch()
  const 当前计算循环名称 = useAppSelector((state) => state?.data?.当前计算循环名称)

  const { 全部循环 = [] } = useCycle()

  const 切换循环 = (val) => {
    const 目标循环数据 = 全部循环?.find((item) => item?.名称 === val)
    dispatch(更新方案数据({ 属性: '当前计算循环名称', 数据: val }))
    dispatch(更新方案数据({ 属性: '当前奇穴信息', 数据: 目标循环数据?.奇穴 }))
    if (目标循环数据?.秘籍) {
      dispatch(更新方案数据({ 属性: '当前秘籍信息', 数据: 目标循环数据?.秘籍 }))
    }
    dispatch(触发秒伤计算({ 是否更新显示计算结果: true }))
  }

  return (
    <div className='common-item'>
      <h1 className='common-label'>循环</h1>
      <div className='common-content'>
        <Select
          value={当前计算循环名称}
          className='cycle-select'
          onChange={(v) => {
            切换循环(v)
          }}
          optionFilterProp='label'
        >
          {全部循环.map((item) => {
            const cls = classnames(
              'cycle-select-item-tag',
              item.标记 === '紫武' ? 'cycle-select-item-tag-purple' : '',
              item.标记 === '橙武' ? 'cycle-select-item-tag-orange' : '',
              item.标记 === '助手' ? 'cycle-select-item-tag-green' : ''
            )

            const 快照计算列表 = 根据循环判断快照计算列表(JSON.stringify(item?.循环详情))
            return (
              <Select.Option value={item?.名称} key={item.名称} label={item.名称}>
                <Popover
                  placement='right'
                  zIndex={2000}
                  title='说明'
                  open={item.提供者 || item.备注 ? undefined : false}
                  content={
                    item.提供者 || item.备注 || 快照计算列表?.length ? (
                      <div className='cycle-select-popover-content'>
                        <>
                          {item.提供者 !== '模拟' ? (
                            <p>
                              该循环计算数据JCL由
                              <span className='cycle-select-provider'> {item.提供者} </span>
                              提供
                            </p>
                          ) : (
                            <p>
                              该循环计算数据由
                              <span className='cycle-select-provider'> 模拟器 </span>生成
                            </p>
                          )}
                          <p dangerouslySetInnerHTML={{ __html: item?.备注 as any }} />
                        </>
                        {快照计算列表?.length ? (
                          <div>
                            以下增益支持快照计算
                            <div>
                              {快照计算列表?.map((data, index) => {
                                return (
                                  <span
                                    className='cycle-select-provider'
                                    key={`增益快照计算${data}`}
                                  >
                                    {data?.replace('_快照', '')}
                                    {index < (快照计算列表?.length || 0) - 1 ? '｜' : ''}
                                  </span>
                                )
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div>该循环无特殊说明</div>
                    )
                  }
                >
                  <div className='cycle-select-item'>
                    {item.标题 || item.名称}
                    <div>
                      {item.提供者 ? (
                        <span className='cycle-select-provider'>{item.提供者}</span>
                      ) : null}
                      <span className={cls}>{item.标记}</span>
                    </div>
                  </div>
                </Popover>
              </Select.Option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default 循环选择
