import { 属性加成, 属性类型 } from '@/@types/属性'
import { 最终计算属性类型 } from '@/@types/计算'

/**
 * @name 优化版通用增益计算
 * @description 优化版通用增益计算，直接修改传入的对象，不返回新对象
 * @description 使用映射表处理计算，减少switch判断
 */

export const 优化版通用增益计算 = (增益: 属性加成, 结果属性: 最终计算属性类型): void => {
  // 改为void，直接修改对象
  const { 值, 属性 } = 增益

  // 使用快速查找表代替switch
  const processor = 增益处理映射表.get(属性)
  if (processor) {
    processor(值, 结果属性)
  }

  // 保留破招转非侠的特殊处理
  if (属性 === 属性类型.破招转非侠) {
    const 当前破招 = 结果属性.最终人物属性.破招值 || 0
    const 转化值 = Math.floor((当前破招 / (值 * 100)) * 1024) / 1024
    if (转化值) {
      结果属性.技能增伤.非侠增伤 += 转化值
    }
  }
}

/**
 * @name 优化版通用增益计算
 * @description 优化版通用增益计算，直接修改传入的对象，不返回新对象
 * @description 使用映射表处理计算，减少switch判断
 */

const 增益处理映射表 = new Map<属性类型, (值: number | any, 结果属性: 最终计算属性类型) => void>([
  [
    属性类型.全属性,
    (v, a) => {
      a.最终人物属性.力道 += v
      a.最终人物属性.根骨 += v
      a.最终人物属性.身法 += v
      a.最终人物属性.元气 += v
      a.最终人物属性.体质 += v
    },
  ],
  [属性类型.力道, (v, a) => (a.最终人物属性.力道 += v)],
  [属性类型.体质, (v, a) => (a.最终人物属性.体质 += v)],
  [属性类型.郭氏体质, (v, a) => (a.气血加成.郭氏体质 += v)],
  [属性类型.郭氏基础气血上限, (v, a) => (a.气血加成.郭氏基础气血上限 += v)],
  [属性类型.郭氏最终气血上限, (v, a) => (a.气血加成.郭氏最终气血上限 += v)],
  [属性类型.基础气血上限, (v, a) => (a.最终人物属性.基础气血上限 += v)],
  [属性类型.额外气血上限, (v, a) => (a.最终人物属性.额外气血上限 += v)],
  [属性类型.根骨, (v, a) => (a.最终人物属性.根骨 += v)],
  [属性类型.身法, (v, a) => (a.最终人物属性.身法 += v)],
  [属性类型.元气, (v, a) => (a.最终人物属性.元气 += v)],
  [属性类型.郭氏力道, (v, a) => (a.郭氏力道 = (a.郭氏力道 || 0) + v)],
  [属性类型.郭氏根骨, (v, a) => (a.郭氏根骨 = (a.郭氏根骨 || 0) + v)],
  [属性类型.郭氏身法, (v, a) => (a.郭氏身法 = (a.郭氏身法 || 0) + v)],
  [属性类型.郭氏元气, (v, a) => (a.郭氏元气 = (a.郭氏元气 || 0) + v)],
  [属性类型.无双等级, (v, a) => (a.最终人物属性.无双等级 += v)],
  [属性类型.郭氏无双, (v, a) => (a.郭氏无双 += v)],
  [属性类型.郭氏额外无双, (v, a) => (a.郭氏额外无双等级 += v)],
  [属性类型.破招值, (v, a) => (a.最终人物属性.破招值 += v)],
  [属性类型.郭氏破招, (v, a) => (a.郭氏破招 += v)],
  [属性类型.全能等级, (v, a) => (a.最终人物属性.全能等级 += v)],
  [属性类型.全基础攻击, (v, a) => (a.最终人物属性.基础攻击 += v)],
  [属性类型.外功基础攻击, (v, a) => (a.最终人物属性.基础攻击 += v)],
  [属性类型.内功基础攻击, (v, a) => (a.最终人物属性.基础攻击 += v)],
  [属性类型.郭氏全基础攻击, (v, a) => (a.郭氏基础攻击 += v)],
  [属性类型.郭氏外功基础攻击, (v, a) => (a.郭氏基础攻击 += v)],
  [属性类型.郭氏内功基础攻击, (v, a) => (a.郭氏基础攻击 += v)],
  [属性类型.全破防等级, (v, a) => (a.最终人物属性.破防等级 += v)],
  [属性类型.外功破防等级, (v, a) => (a.最终人物属性.破防等级 += v)],
  [属性类型.内功破防等级, (v, a) => (a.最终人物属性.破防等级 += v)],
  [属性类型.郭氏全破防等级, (v, a) => (a.郭氏破防等级 += v)],
  [属性类型.郭氏外功破防等级, (v, a) => (a.郭氏破防等级 += v)],
  [属性类型.郭氏内功破防等级, (v, a) => (a.郭氏破防等级 += v)],
  [属性类型.全会心等级, (v, a) => (a.最终人物属性.会心等级 += v)],
  [属性类型.外功会心等级, (v, a) => (a.最终人物属性.会心等级 += v)],
  [属性类型.内功会心等级, (v, a) => (a.最终人物属性.会心等级 += v)],
  [属性类型.全会心百分比, (v, a) => (a.额外会心率 += v)],
  [属性类型.外功会心百分比, (v, a) => (a.额外会心率 += v)],
  [属性类型.内功会心百分比, (v, a) => (a.额外会心率 += v)],
  [属性类型.全会心效果等级, (v, a) => (a.最终人物属性.会心效果等级 += v)],
  [属性类型.外功会心效果等级, (v, a) => (a.最终人物属性.会心效果等级 += v)],
  [属性类型.内功会心效果等级, (v, a) => (a.最终人物属性.会心效果等级 += v)],
  [属性类型.郭氏全会心效果等级, (v, a) => (a.郭氏额外会效果值 += v)],
  [属性类型.郭氏外功会心效果等级, (v, a) => (a.郭氏额外会效果值 += v)],
  [属性类型.郭氏内功会心效果等级, (v, a) => (a.郭氏额外会效果值 += v)],
  [属性类型.郭氏突破上限会心效果等级, (v, a) => (a.郭氏突破上限会心效果值 += v)],
  [
    属性类型.内功基础防御,
    (v, a) => {
      a.计算目标 = {
        ...a.计算目标,
        防御点数: Math.max(a.计算目标.防御点数 + v, 0),
      }
    },
  ],
  [
    属性类型.外功基础防御,
    (v, a) => {
      a.计算目标 = {
        ...a.计算目标,
        防御点数: Math.max(a.计算目标.防御点数 + v, 0),
      }
    },
  ],
  [属性类型.郭氏外功基础防御, (v, a) => (a.郭氏属性无视防御 += v)],
  [属性类型.郭氏内功基础防御, (v, a) => (a.郭氏属性无视防御 += v)],
  [属性类型.郭氏全无视防御, (v, a) => (a.郭氏无视防御 += v)],
  [属性类型.加速等级, (v, a) => (a.最终人物属性.加速等级 += v)],
  [
    属性类型.武器伤害,
    (v, a) => {
      a.最终人物属性.武器伤害_最小值 += v
      a.最终人物属性.武器伤害_最大值 += v
    },
  ],
  [属性类型.郭氏武器伤害, (v, a) => (a.郭氏武器伤害 += v)],
  [属性类型.全局伤害因子, (v, a) => (a.技能增伤.全局伤害因子 += v)],
  [属性类型.目标移动状增伤, (v, a) => (a.技能增伤.目标移动状增伤 += v)],
  [属性类型.通用增伤, (v, a) => (a.技能增伤.通用增伤 += v)],
  [属性类型.易伤增伤, (v, a) => (a.技能增伤.易伤增伤 += v)],
  [属性类型.非侠增伤, (v, a) => (a.技能增伤.非侠增伤 += v)],
  [属性类型.系数增伤, (v, a) => (a.技能增伤.系数增伤 = a.技能增伤.系数增伤 * v)],
  [属性类型.系数置换函数, (v, a) => (a.技能增伤.系数置换函数 = v)],
])
