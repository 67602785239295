import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 裁叶饮刃增益 from './技能增益/裁叶饮刃'
import 银光照雪增益 from './技能增益/银光照雪'
import 且待时休增益 from './技能增益/且待时休'
import 川乌射罔增益 from './技能增益/川乌射罔'
import 钩吻断肠增益 from './技能增益/钩吻断肠'
import 商陆缀寒增益 from './技能增益/商陆缀寒'
import 无方中和增益 from './技能增益/无方中和'
import 苍棘缚地增益 from './技能增益/苍棘缚地'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

// 27560
const 逆乱数据: Partial<技能基础数据模型> = {
  统计名称: '逆乱(DOT)',
  DOT生效间隔: 32,
  DOT跳数: 8,
  基础伤害_基础值: 95,
  技能伤害系数: 164.65680000000003,
  技能增益列表: 通用增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32841',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(470810.62399999984),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(470810.62399999984) },
      2: { 技能伤害系数: 获取破招实际系数(1868646.3180799996) },
      3: { 技能伤害系数: 获取破招实际系数(-440821.3504) },
      4: { 技能伤害系数: 获取破招实际系数(-35651.58400000015) },
      5: { 技能伤害系数: 获取破招实际系数(977272.8319999997) },
      6: { 技能伤害系数: 获取破招实际系数(1990197.2479999997) },
    },
  },
  {
    技能ID: '27451',
    技能名称: '裁叶饮刃',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 裁叶饮刃增益,
  },
  {
    技能ID: '28081',
    技能名称: '无方中和',
    技能伤害系数: 232.75676160000012,
    基础伤害_基础值: 100,
    基础伤害_浮动值: 10,
    技能增益列表: 无方中和增益,
  },
  {
    技能ID: '20052_27560', // 20052_27560
    技能名称: '逆乱(DOT)',
    ...逆乱数据,
  },
  {
    技能ID: '27552',
    技能名称: '商陆缀寒',
    技能伤害系数: 253.0,
    基础伤害_基础值: 379,
    基础伤害_浮动值: 20,
    技能增益列表: 商陆缀寒增益,
  },
  {
    技能ID: '27555',
    技能名称: '钩吻断肠',
    技能伤害系数: 120,
    基础伤害_基础值: 114,
    基础伤害_浮动值: 10,
    技能增益列表: 钩吻断肠增益,
  },
  {
    技能ID: '27557',
    技能名称: '川乌射罔',
    技能伤害系数: 228.8,
    基础伤害_基础值: 144,
    基础伤害_浮动值: 10,
    技能增益列表: 川乌射罔增益,
  },
  {
    技能ID: '27579',
    技能名称: '沾衣未妨',
    统计名称: '沾衣未妨',
    技能伤害系数: 150 * 0.8,
    基础伤害_基础值: 125,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '27584',
    技能名称: '且待时休',
    统计名称: '且待时休',
    技能伤害系数: 72 * 1.8 * 1.2 * 1.15 * 1.15,
    基础伤害_基础值: 206,
    基础伤害_浮动值: 10,
    技能增益列表: 且待时休增益,
  },
  {
    技能ID: '28409',
    技能名称: '且待时休·苍棘缚地',
    秘籍依赖技能: '且待时休',
    统计名称: '且待时休',
    技能伤害系数: 72 * 1.8 * 1.2 * 1.15 * 1.15,
    基础伤害_基础值: 206,
    基础伤害_浮动值: 10,
    技能增益列表: 且待时休增益,
  },
  {
    技能ID: '28346',
    技能名称: '银光照雪',
    秘籍依赖技能: '银光照雪',
    技能伤害系数: 120 * 1.1,
    基础伤害_基础值: 410,
    基础伤害_浮动值: 20,
    技能增益列表: 银光照雪增益,
  },
  {
    技能ID: '34699',
    技能名称: '银光照雪·结草',
    秘籍依赖技能: '银光照雪',
    技能伤害系数: 120 * 1.1,
    基础伤害_基础值: 410,
    基础伤害_浮动值: 20,
    技能增益列表: 银光照雪增益,
  },
  {
    技能ID: '27539',
    技能名称: '惊鸿掠水',
    技能伤害系数: 100,
    基础伤害_基础值: 156,
    基础伤害_浮动值: 15,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '29505',
    技能名称: '含锋破月',
    技能伤害系数: 400.7241700000001,
    基础伤害_基础值: 575,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34700',
    技能名称: '含锋破月·结草',
    技能伤害系数: 400.7241700000001,
    基础伤害_基础值: 575,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '29506',
    技能名称: '飞叶满襟',
    技能伤害系数: 486.59363500000006,
    基础伤害_基础值: 625,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '34702',
    技能名称: '飞叶满襟·结草',
    技能伤害系数: 365.585 * 1.1 * 1.1 * 1.1,
    基础伤害_基础值: 625,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '30735',
    技能名称: '折枝拂露',
    技能伤害系数: 410 * 1.15 * 1.1 * 0.65,
    基础伤害_基础值: 730,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '32922',
    技能名称: '折枝留春',
    技能伤害系数: 600,
    基础伤害_基础值: 730,
    基础伤害_浮动值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '27657',
    技能名称: '苍棘缚地',
    技能伤害系数: 297.0,
    基础伤害_基础值: 137,
    基础伤害_浮动值: 10,
    技能增益列表: 苍棘缚地增益,
  },
  {
    技能ID: '29674',
    技能名称: '疾根',
    技能伤害系数: 170 * 0.5 * 0.7 * 1.1,
    基础伤害_基础值: 120,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28385',
    技能名称: '紫叶沉疴',
    技能伤害系数: 190,
    基础伤害_基础值: 417,
    基础伤害_浮动值: 15,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '28434',
    技能名称: '紫伏',
    技能伤害系数: 309.37500000000006,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38636',
    技能名称: '汲刺',
    技能伤害系数: 540,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '35367',
    技能名称: '应理与药',
    技能伤害系数: 580 * 1.15 * 1.5,
    基础伤害_基础值: 100,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '29698',
    技能名称: '商陆缀寒·神兵',
    技能伤害系数: 65,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '29695',
    技能名称: '鹿王本生',
    统计名称: '烬灭',
    技能伤害系数: 220,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36068',
    技能名称: '荆障',
    技能伤害系数: 125,
    基础伤害_基础值: 33,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  裁叶饮刃增益,
  银光照雪增益,
  且待时休增益,
  川乌射罔增益,
  钩吻断肠增益,
  商陆缀寒增益,
  无方中和增益,
}

export { 技能增益 }
