import { 技能基础数据模型 } from '@/@types/技能'

import 通用增益 from './技能增益/通用增益/通用增益'
import 梅花枪法增益 from './技能增益/梅花枪法'
import 穿云增益 from './技能增益/穿云'
import 龙吟增益 from './技能增益/龙吟'
import 灭增益 from './技能增益/灭'
import 龙牙增益 from './技能增益/龙牙'
import 突增益 from './技能增益/突'
import 断魂刺增益 from './技能增益/断魂刺'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'
import 流血增益 from './技能增益/流血'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '12',
    技能名称: '梅花枪法',
    统计名称: '梅花枪法',
    技能伤害系数: 27,
    武器伤害系数: 1,
    技能增益列表: 梅花枪法增益,
  },
  {
    技能ID: '32820',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-598736.896),
    技能增益列表: 通用增益,
  },
  {
    技能ID: '18207',
    技能名称: '穿云·一',
    统计名称: '穿云',
    秘籍依赖技能: '穿云',
    技能伤害系数: 353.6539406400002,
    基础伤害_基础值: 160,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 穿云增益,
  },
  {
    技能ID: '18208',
    技能名称: '穿云·二',
    统计名称: '穿云',
    秘籍依赖技能: '穿云',
    技能伤害系数: 353.6539406400002,
    基础伤害_基础值: 160,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 穿云增益,
  },
  {
    技能ID: '18603',
    技能名称: '龙吟',
    统计名称: '龙吟',
    技能伤害系数: 480.4164214080002,
    基础伤害_基础值: 193,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 龙吟增益,
  },
  {
    技能ID: '37618',
    技能名称: '龙吟·大漠',
    统计名称: '龙吟·大漠',
    技能伤害系数: 350,
    基础伤害_基础值: 70,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '701',
    技能名称: '灭·斩杀',
    统计名称: '灭',
    秘籍依赖技能: '灭',
    技能伤害系数: 314.16,
    基础伤害_基础值: 208,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 灭增益,
  },
  {
    技能ID: '702',
    技能名称: '灭',
    统计名称: '灭',
    秘籍依赖技能: '灭',
    技能伤害系数: 209.44000000000003,
    基础伤害_基础值: 208,
    基础伤害_浮动值: 10,
    武器伤害系数: 1,
    技能增益列表: 灭增益,
  },
  {
    技能ID: '6525',
    技能名称: '灭·破楼兰·一',
    统计名称: '灭',
    秘籍依赖技能: '灭',
    技能伤害系数: 314.16,
    基础伤害_基础值: 114,
    基础伤害_浮动值: 12,
    武器伤害系数: 1,
    技能增益列表: 灭增益,
  },
  {
    技能ID: '6526',
    技能名称: '灭·破楼兰·二',
    统计名称: '灭',
    秘籍依赖技能: '灭',
    技能伤害系数: 209.44000000000003,
    基础伤害_基础值: 57,
    基础伤害_浮动值: 6,
    武器伤害系数: 0.25,
    技能增益列表: 灭增益,
  },
  {
    技能ID: '24898',
    技能名称: '灭·骑驭',
    统计名称: '灭',
    秘籍依赖技能: '灭',
    技能伤害系数: 42,
    武器伤害系数: 1,
    技能增益列表: 灭增益,
  },
  {
    技能ID: '431',
    技能名称: '突',
    统计名称: '突',
    技能伤害系数: 16,
    基础伤害_基础值: 37,
    基础伤害_浮动值: 3,
    武器伤害系数: 1,
    技能增益列表: 突增益,
  },
  {
    技能ID: '15002',
    技能名称: '龙牙·龙血',
    统计名称: '龙牙',
    秘籍依赖技能: '龙牙',
    技能伤害系数: 214.94162380261048,
    基础伤害_基础值: 22,
    基础伤害_浮动值: 1,
    武器伤害系数: 0.1,
    技能增益列表: 龙牙增益,
  },
  {
    技能ID: '18773',
    技能名称: '龙牙',
    统计名称: '龙牙',
    秘籍依赖技能: '龙牙',
    技能伤害系数: 537.3540595065261,
    基础伤害_基础值: 222,
    基础伤害_浮动值: 15,
    武器伤害系数: 1,
    技能增益列表: 龙牙增益,
  },
  {
    技能ID: '36568',
    技能名称: '击水',
    统计名称: '击水',
    技能伤害系数: 415.8,
    基础伤害_基础值: 78,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '409',
    技能名称: '断魂刺',
    统计名称: '断魂刺',
    技能伤害系数: 16,
    基础伤害_基础值: 39,
    基础伤害_浮动值: 15,
    武器伤害系数: 3,
    技能增益列表: 断魂刺增益,
  },
  {
    技能ID: '12461', // 401
    技能名称: '流血(DOT)', // 击水流血
    统计名称: '流血(DOT)',
    技能伤害系数: 552.4336263600001, // 401
    DOT生效间隔: 32,
    DOT跳数: 7, // 最大三层
    基础伤害_基础值: 60,
    技能增益列表: 流血增益,
  },
  // 还有一个牙璋流血写
  {
    技能ID: '3442', // 18591
    技能名称: '流血(DOT)·普通',
    统计名称: '流血(DOT)·普通',
    技能伤害系数: 552.4336263600001, // 18591
    DOT生效间隔: 32,
    DOT跳数: 7, // 最大两层
    基础伤害_基础值: 60,
    技能增益列表: 流血增益,
  },
  {
    技能ID: '25772',
    技能名称: '龙牙·神兵',
    技能伤害系数: 50,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '31031',
    技能名称: '画角闻龙',
    技能伤害系数: 205,
    基础伤害_基础值: 496,
    基础伤害_浮动值: 52,
    技能增益列表: 通用增益,
  },
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }

export default 技能基础数据
