import { 技能基础数据模型 } from '@/@types/技能'

const 心法通用系数 = (通用增益) => {
  const 通用系数: 技能基础数据模型[] = [
    {
      // 龙门武器
      技能ID: '29919',
      技能名称: '剑风',
      基础伤害_基础值: 31590,
      技能增益列表: 通用增益,
    },
  ]
  return 通用系数
}

export default 心法通用系数
