import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 594 / 1024 }],
  },
  {
    增益名称: '无视防御',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 614 }],
  },
  {
    增益名称: '斩涛·悟',
    增益所在位置: '奇穴',
    是否常驻面板: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏力道, 值: 154 }],
  },
  {
    // 会心 - 70162
    // 会心效果 - 70163
    // 增加伤害 - 70161
    增益名称: '灭影追风·悟',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.15 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 200 },
      { 属性: 属性类型.通用增伤, 值: 103 / 1024 },
      { 属性: 属性类型.郭氏额外无双, 值: 154 }, // 秘籍
    ],
  },
  // {
  //   // 70167
  //   增益名称: '灭影追风·悟·无双',
  //   增益所在位置: '技能',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏额外无双, 值: 103 }],
  // },
  {
    // 71297
    增益名称: '披靡·悟·1',
    依赖奇穴: '披靡·悟',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 103 / 1024 }],
  },
  {
    增益名称: '披靡·悟·2',
    依赖奇穴: '披靡·悟',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: (103 * 2) / 1024 }],
  },
  {
    增益名称: '披靡·悟·3',
    依赖奇穴: '披靡·悟',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: (103 * 2) / 1024 }],
  },
  {
    // 横云势秘籍-秘章
    // 70161
    增益名称: '横云一式',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 103 / 1024 }],
  },
  // {
  //   // 停云势-绝篇
  //   // 70191 化劲降低10%，疑似为bug。这里用修改系数来处理
  //   增益名称: '停云势',
  //   增益所在位置: '技能',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.全局伤害因子, 值: 103 / 1024 }],
  // },
  {
    // 大橙武增伤
    // 70161-154
    增益名称: '大橙武增伤',
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 154 / 1024 }],
  },
]

export default 通用增益
