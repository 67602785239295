import { 技能基础数据模型, 技能增益列表类型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 三柴剑法增益 from './技能增益/三柴剑法'
import 六合独尊增益 from './技能增益/六合独尊'
import 万世不竭增益 from './技能增益/万世不竭'
import 两仪化形增益 from './技能增益/两仪化形'
import 四象轮回增益 from './技能增益/四象轮回'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'
import { 属性类型 } from '@/@types/属性'

const 获取飞剑增伤 = (层数 = 0) => {
  const 增伤数组 = [0, 154, 307, 461, 614]
  return [
    ...万世不竭增益,
    {
      增益名称: `飞剑增伤·${层数}`,
      增益所在位置: '奇穴',
      依赖奇穴: '固本',
      增益类型: '全局启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 增伤数组[层数] / 1024 }],
    },
  ] as 技能增益列表类型[]
}

const 飞剑统一函数 = {
  统计名称: '飞剑',
  秘籍依赖技能: '万世不竭',
  技能伤害系数: 55,
  基础伤害_基础值: 50,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '32813',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-979369.9839999999),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-979369.9839999999) },
      2: { 技能伤害系数: 获取破招实际系数(-702545.9199999999) },
      3: { 技能伤害系数: 获取破招实际系数(-148897.79200000002) },
      4: { 技能伤害系数: 获取破招实际系数(-287309.824) },
    },
  },
  {
    技能ID: '303',
    技能名称: '三才化生',
    技能伤害系数: 16,
    基础伤害_基础值: 78,
    基础伤害_浮动值: 7,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '305',
    技能名称: '九转归一',
    技能伤害系数: 16,
    基础伤害_基础值: 62,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '327',
    技能名称: '五方行尽·一',
    统计名称: '五方行尽',
    技能伤害系数: 11.2,
    基础伤害_基础值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '328',
    技能名称: '五方行尽·二',
    统计名称: '五方行尽',
    技能伤害系数: 22.4,
    基础伤害_基础值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '329',
    技能名称: '五方行尽·三',
    统计名称: '五方行尽',
    技能伤害系数: 33.599999999999994,
    基础伤害_基础值: 15,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '330',
    技能名称: '五方行尽·四',
    统计名称: '五方行尽',
    技能伤害系数: 44.8,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '331',
    技能名称: '五方行尽·五',
    统计名称: '五方行尽',
    技能伤害系数: 56,
    基础伤害_基础值: 25,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '461',
    技能名称: '五方行尽·六',
    统计名称: '五方行尽',
    技能伤害系数: 67.19999999999999,
    基础伤害_基础值: 30,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '462',
    技能名称: '五方行尽·七',
    统计名称: '五方行尽',
    技能伤害系数: 78.39999999999999,
    基础伤害_基础值: 35,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '463',
    技能名称: '五方行尽·八',
    统计名称: '五方行尽',
    技能伤害系数: 89.6,
    基础伤害_基础值: 40,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '464',
    技能名称: '五方行尽·九',
    统计名称: '五方行尽',
    技能伤害系数: 100.8,
    基础伤害_基础值: 45,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '465',
    技能名称: '五方行尽·十',
    统计名称: '五方行尽',
    技能伤害系数: 112,
    基础伤害_基础值: 51,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '896',
    技能名称: '四象轮回',
    技能伤害系数: 528.0975000000001,
    基础伤害_基础值: 180,
    基础伤害_浮动值: 20,
    技能增益列表: 四象轮回增益,
  },
  {
    技能ID: '2299',
    技能名称: '太极无极',
    技能伤害系数: 88,
    基础伤害_基础值: 53,
    基础伤害_浮动值: 46,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '3439',
    技能名称: '两仪化形·一',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 51.660000000000004,
    基础伤害_基础值: 272,
    基础伤害_浮动值: 2,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3440',
    技能名称: '两仪化形·二',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 103.32000000000001,
    基础伤害_基础值: 545,
    基础伤害_浮动值: 5,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3441',
    技能名称: '两仪化形·三',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 154.98000000000002,
    基础伤害_基础值: 818,
    基础伤害_浮动值: 8,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3442',
    技能名称: '两仪化形·四',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 206.64000000000001,
    基础伤害_基础值: 1090,
    基础伤害_浮动值: 10,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3443',
    技能名称: '两仪化形·五',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 258.3,
    基础伤害_基础值: 1363,
    基础伤害_浮动值: 13,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3444',
    技能名称: '两仪化形·六',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 309.96000000000004,
    基础伤害_基础值: 1636,
    基础伤害_浮动值: 16,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3445',
    技能名称: '两仪化形·七',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 361.62000000000006,
    基础伤害_基础值: 1908,
    基础伤害_浮动值: 19,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3446',
    技能名称: '两仪化形·八',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 413.28000000000003,
    基础伤害_基础值: 2181,
    基础伤害_浮动值: 21,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3447',
    技能名称: '两仪化形·九',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 464.94000000000005,
    基础伤害_基础值: 2454,
    基础伤害_浮动值: 24,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '3448',
    技能名称: '两仪化形·十',
    统计名称: '两仪化形',
    秘籍依赖技能: '两仪化形',
    技能伤害系数: 516.6,
    基础伤害_基础值: 2726,
    基础伤害_浮动值: 27,
    技能增益列表: 两仪化形增益,
  },
  {
    技能ID: '18121',
    伤害类型: '外功',
    技能名称: '三柴剑法',
    技能伤害系数: 21,
    武器伤害系数: 1,
    技能增益列表: 三柴剑法增益,
  },
  {
    技能ID: '18649',
    技能名称: '飞剑·一',
    ...飞剑统一函数,
    技能增益列表: 获取飞剑增伤(0),
  },
  {
    技能ID: '18650',
    技能名称: '飞剑·二',
    ...飞剑统一函数,
    技能增益列表: 获取飞剑增伤(1),
  },
  {
    技能ID: '18651',
    技能名称: '飞剑·三',
    ...飞剑统一函数,
    技能增益列表: 获取飞剑增伤(2),
  },
  {
    技能ID: '18652',
    技能名称: '飞剑·四',
    ...飞剑统一函数,
    技能增益列表: 获取飞剑增伤(3),
  },
  {
    技能ID: '18653',
    技能名称: '飞剑·五',
    ...飞剑统一函数,
    技能增益列表: 获取飞剑增伤(4),
  },
  {
    技能ID: '18670',
    技能名称: '六合独尊',
    统计名称: '六合独尊',
    技能伤害系数: 446.25,
    基础伤害_基础值: 52,
    技能增益列表: 六合独尊增益,
  },
  {
    // 六合独尊命中黑洞伤害，为了技能数凑齐显示，实际伤害为1
    技能ID: '18670',
    技能名称: '六合独尊·黑洞',
    统计名称: '六合独尊',
    真实伤害: 1,
  },
  {
    技能ID: '22014_1',
    技能名称: '万世不竭·一',
    统计名称: '万世不竭',
    秘籍依赖技能: '万世不竭',
    技能伤害系数: 656.0,
    基础伤害_基础值: 600,
    基础伤害_浮动值: 8,
    技能增益列表: 万世不竭增益,
  },
  {
    技能ID: '22014_2',
    技能名称: '万世不竭·二',
    统计名称: '万世不竭',
    秘籍依赖技能: '万世不竭',
    技能伤害系数: 754.4,
    基础伤害_基础值: 750,
    基础伤害_浮动值: 16,
    技能增益列表: 万世不竭增益,
  },
  {
    技能ID: '25770',
    技能名称: '四象轮回·神兵',
    技能伤害系数: 65,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36439',
    技能名称: '颠越苍穹击',
    技能伤害系数: 263.99538,
    基础伤害_基础值: 1038,
    基础伤害_浮动值: 104,
    技能增益列表: 通用增益,
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
