import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'

const 碧海飘渺掌增益: 技能增益列表类型[] = [
  {
    增益名称: '驰行', // 14321
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    增益名称: '澄穆·1', // 19253
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  {
    增益名称: '澄穆·2', // 19254
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 205 / 1024 }],
  },
  {
    增益名称: '澄穆·3', // 19255
    增益所在位置: '技能',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 307 / 1024 }],
  },
  {
    增益名称: '鸿轨', // 29348
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.15 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 154 },
    ],
  },
]

export default 碧海飘渺掌增益
