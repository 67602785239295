import React, { useEffect, useRef, useState } from 'react'
import { Chart } from '@antv/g2'
import dataJson from './data.json'
import colorMap from './color.json'
import styles from './index.module.less'
import './index.css'

function 技改图表弹窗({ 数据源, 排序规则 }) {
  const [chartData, setChartData] = useState<any>()
  const limitRef: any = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      initChart()
    }, 20)
    return () => {
      limitRef.current = false
      setChartData(undefined)
    }
  }, [排序规则])

  // 按规则排序
  const 按规则排序数据 = (data) => {
    const list = data.map((obj) => {
      return {
        ...obj,
        range: [obj.low, obj.q1, obj.median, obj.q3, obj.high],
      }
    })
    list.sort((a, b) => {
      return a.range[排序规则] - b.range[排序规则]
    })
    return list
  }

  const initChart = () => {
    if (limitRef.current) {
      return
    }
    limitRef.current = true

    const chart = chartData
      ? chartData
      : new Chart({
          container: 'dps-change-count-chart',
          autoFit: true,
          renderer: 'canvas',
          padding: [50, 100, 50, 100],
        })

    chart.tooltip({
      crosshairs: false,
    })

    if (!chartData) {
      setChartData(chart)
    }

    chart.scale('range', {
      nice: true,
    })

    const data = 按规则排序数据(dataJson[数据源])

    chart.data(data)
    chart.coordinate().transpose()

    chart.tooltip({
      showTitle: false,
      itemTpl:
        '<li data-index={index} style="padding: 4px">' +
        `<h1 class="change-tooltip-list-item-title">{name}</h1>` +
        '<span class="change-tooltip-list-item">前5%：{high}</span>' +
        '<span class="change-tooltip-list-item">前20%：{q3}</span>' +
        '<span class="change-tooltip-list-item">前40%：{median}</span>' +
        '<span class="change-tooltip-list-item">前60%：{q1}</span>' +
        '<span class="change-tooltip-list-item">前80%：{low}</span>' +
        '</li>',
    })

    // 修改 schema position 为横向展示：Y*数值
    chart.axis('x', {
      label: {
        style: {
          fill: '#000',
          fontSize: 14, // 文本大小
        },
      },
    })
    chart.axis('range', {
      label: {
        style: {
          fill: '#000',
          fontSize: 14, // 文本大小
        },
      },
    })
    chart
      .schema()
      .position('x*range') // 改为 range 在 x 轴，x 在 y 轴
      .shape('box')
      .style('x', (val) => {
        return {
          stroke: '#545454',
          fill: colorMap[val],
          fillOpacity: 0.85,
        }
      })
      .tooltip('x*low*q1*median*q3*high', (x, low, q1, median, q3, high) => {
        return {
          name: x,
          low: low,
          q1: q1,
          median: median,
          q3: q3,
          high: high,
        }
      })

    chart.render()

    setTimeout(() => {
      limitRef.current = false
    }, 10)
  }

  return <div id='dps-change-count-chart' className={styles.chart} />
}

export default 技改图表弹窗
