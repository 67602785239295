import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import { 属性类型 } from '@/@types/属性'
import 门派套装增益 from './通用增益/门派套装增益'
import 大橙武技能增益 from './通用增益/大橙武技能增益'

const 引窍增益: 技能增益列表类型[] = [
  ...通用增益,
  ...门派套装增益,
  ...大橙武技能增益,
  {
    增益名称: '涌泉',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [
      {
        属性: 属性类型.系数置换函数,
        值: (i) => 1000 * (1 + 0.04 * (i - 1)) * 0.88 * 0.9 * 0.95 * 0.9 * 0.9 * 0.85 * 0.85,
      },
      { 属性: 属性类型.非侠增伤, 值: 994 / 1024 },
    ],
  },
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.03 }],
  },
  {
    增益名称: '减CD_1',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
  {
    增益名称: '减CD_2',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
]

export default 引窍增益
