import { 五彩石数据类型 } from '@/@types/五彩石'
import { 属性类型 } from '@/@types/属性'
import { 五彩石增益类型枚举 } from '@/@types/枚举'

const 五彩石_五级: 五彩石数据类型[] = [
  {
    五彩石名称: '彩·灵根·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·灵根·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·灵根·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灵根·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·灵根·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灵根·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·破招·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·破招·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·破招·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·破招·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·破招·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·破招·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·无双·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·无双·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·无双·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·无双·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·无双·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·无双·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·真元·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·真元·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·真元·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·真元·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·真元·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·真元·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·激流·灭气·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·灭气·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·月华·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·月华·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·破招·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·破招·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·激流·无双·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·无双·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·激流·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·灭气·激流·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·灭气·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·月华·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·灭气·月华·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·灭气·破招·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·破招·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·无双·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灭气·无双·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 1219 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·星见·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·星见·激流·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·星见·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·星见·灭气·月华(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 4875,
      },
    ],
  },
  {
    五彩石名称: '彩·星见·月华·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·星见·月华·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.内功会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 1219 },
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 2438,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·激流·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·激流·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·激流·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·灭气·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·月华·灭气·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·灭气·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 2438 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·破招·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·月华·破招·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·月华·无双·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·月华·无双·灭气(伍)',
    五彩石等级: 5,
    装备增益: [
      {
        增益类型: 属性类型.内功会心效果等级,
        增益名称: 五彩石增益类型枚举.会心效果等级,
        增益数值: 1219,
      },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      { 增益类型: 属性类型.内功破防等级, 增益名称: 五彩石增益类型枚举.破防等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·击破·强击·聚魂(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.全会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 2438 },
      { 增益类型: 属性类型.郭氏元气, 增益名称: 五彩石增益类型枚举.元气百分比, 增益数值: 71 },
    ],
  },
  {
    五彩石名称: '彩·击破·强击·固筋(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.全会心等级, 增益名称: 五彩石增益类型枚举.会心等级, 增益数值: 2438 },
      { 增益类型: 属性类型.郭氏根骨, 增益名称: 五彩石增益类型枚举.根骨百分比, 增益数值: 71 },
    ],
  },
  {
    五彩石名称: '彩·急速·破招·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 1219 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·急速·激流·破招(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·急速·激流·无双(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 1219 },
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 737 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·急速·无双·激流(伍)',
    五彩石等级: 5,
    装备增益: [
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 1219 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·灵根·急速·激流(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.根骨, 增益名称: 五彩石增益类型枚举.根骨, 增益数值: 156 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·真元·急速·激流(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.元气, 增益名称: 五彩石增益类型枚举.元气, 增益数值: 156 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·激流·破招·急速(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 2438 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·急速·破招(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·急速·无双(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·激流·无双·急速(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.内功基础攻击, 增益名称: 五彩石增益类型枚举.内功基础攻击, 增益数值: 368 },
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 2438 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 4875 },
    ],
  },
  {
    五彩石名称: '彩·破招·急速·激流(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.破招值, 增益名称: 五彩石增益类型枚举.破招值, 增益数值: 1219 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
  {
    五彩石名称: '彩·无双·急速·激流(伍)',
    五彩石等级: 6,
    装备增益: [
      { 增益类型: 属性类型.无双等级, 增益名称: 五彩石增益类型枚举.无双等级, 增益数值: 1219 },
      { 增益类型: 属性类型.加速等级, 增益名称: 五彩石增益类型枚举.加速等级, 增益数值: 2438 },
      {
        增益类型: 属性类型.内功基础攻击,
        增益名称: 五彩石增益类型枚举.内功基础攻击,
        增益数值: 1473,
      },
    ],
  },
]

export default 五彩石_五级
