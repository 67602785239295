import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import { 按数字生成数组 } from '@/工具函数/help'

// 12551
const 跬步两仪函数 = () => {
  const 数组 = 按数字生成数组(5)
  const 增伤数组 = [41, 82, 123, 164, 205]
  return 数组.map((item) => {
    return {
      增益名称: `跬步两仪·${item}`,
      增益所在位置: '奇穴',
      依赖奇穴: '跬步',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: 增伤数组[item - 1] / 1024 }],
    } as 技能增益列表类型
  })
}

const 两仪化形增益: 技能增益列表类型[] = [
  ...通用增益,
  {
    增益名称: '5%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 51 / 1024 }],
  },
  {
    增益名称: '4%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 41 / 1024 }],
  },
  {
    增益名称: '3%伤害',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 31 / 1024 }],
  },
  {
    增益名称: '4%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.04 }],
  },
  {
    增益名称: '3%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.03 }],
  },
  {
    增益名称: '2%会心',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.内功会心百分比, 值: 0.02 }],
  },
  {
    增益名称: '回豆',
    增益所在位置: '秘籍',
    增益类型: '全局启用',
    增益集合: [],
  },
  ...跬步两仪函数(),
]

export default 两仪化形增益
