import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'
import 翼绝云天增益 from './技能增益/翼绝云天'
import 木落雁归增益 from './技能增益/木落雁归'
import 飘遥伞击增益 from './技能增益/飘遥伞击'
import 振翅图南增益 from './技能增益/振翅图南'
import 击水三千增益 from './技能增益/击水三千'
import 浮游天地增益 from './技能增益/浮游天地'
import 海运南冥增益 from './技能增益/海运南冥'
import 通用增益 from './技能增益/通用增益/通用'
import 溟海御波增益 from './技能增益/溟海御波'
import 逐波灵游增益 from './技能增益/逐波灵游'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '20016',
    技能名称: '翼绝云天',
    基础伤害_基础值: 417,
    基础伤害_浮动值: 15,
    技能伤害系数: 100 * 0.9 * 2 * 0.85,
    技能增益列表: 翼绝云天增益,
  },
  {
    技能ID: '32815',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (1 - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(1048576 * (0.7 - 1)) },
      2: { 技能伤害系数: 获取破招实际系数(1048576 * (0.875 - 1)) },
      3: { 技能伤害系数: 获取破招实际系数(1048576 * (1.4 - 1)) },
      4: { 技能伤害系数: 获取破招实际系数(1048576 * (1.54 - 1)) },
      5: { 技能伤害系数: 获取破招实际系数(943718.3999999999) },
      6: { 技能伤害系数: 获取破招实际系数(-157286.40000000002) },
    },
  },
  {
    技能ID: '19712',
    技能名称: '飘遥伞击',
    统计名称: '飘遥伞击',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 飘遥伞击增益,
  },
  {
    技能ID: '19712',
    // 物化期间随加速改变系数
    // 公式为max(16, Z-N+1) Z为原始系数 N为加速档位
    技能名称: '飘遥伞击·物化',
    统计名称: '飘遥伞击',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 飘遥伞击增益,
  },
  {
    技能ID: '31250',
    技能名称: '振翅图南',
    基础伤害_基础值: 458,
    基础伤害_浮动值: 10,
    技能伤害系数: 96.37650000000001,
    技能增益列表: 振翅图南增益,
  },
  {
    技能ID: '30503',
    技能名称: '溯徊',
    基础伤害_基础值: 0,
    基础伤害_浮动值: 0,
    技能伤害系数: 2340,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 531.3000000000001, 基础伤害_基础值: 67, 基础伤害_浮动值: 5 },
      2: { 技能伤害系数: 910.8000000000001, 基础伤害_基础值: 122, 基础伤害_浮动值: 5 },
      3: { 技能伤害系数: 2340, 基础伤害_基础值: 0, 基础伤害_浮动值: 0 },
    },
  },
  {
    技能ID: '20054',
    技能名称: '跃潮斩波',
    基础伤害_基础值: 1625,
    基础伤害_浮动值: 10,
    技能伤害系数: 338.013,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '20322',
    技能名称: '溟海御波',
    基础伤害_基础值: 1625,
    基础伤害_浮动值: 15,
    技能伤害系数: 589.8654912150001,
    技能增益列表: 溟海御波增益,
  },
  {
    技能ID: '20684',
    技能名称: '海运南冥·远',
    统计名称: '海运南冥',
    秘籍依赖技能: '海运南冥',
    基础伤害_基础值: 1562,
    基础伤害_浮动值: 15,
    技能伤害系数: 663.9270907500002,
    技能增益列表: 海运南冥增益,
  },
  {
    技能ID: '20685',
    技能名称: '海运南冥·近',
    统计名称: '海运南冥',
    秘籍依赖技能: '海运南冥',
    基础伤害_基础值: 2083,
    基础伤害_浮动值: 15,
    技能伤害系数: 737.6967675000003,
    技能增益列表: 海运南冥增益,
  },
  {
    技能ID: '20323',
    技能名称: '逐波灵游',
    基础伤害_基础值: 833,
    基础伤害_浮动值: 15,
    技能伤害系数: 342.13063500000004,
    技能增益列表: 逐波灵游增益,
  },
  {
    技能ID: '36282',
    技能名称: '定波砥澜',
    统计名称: '定波砥澜',
    基础伤害_基础值: 86,
    基础伤害_浮动值: 5,
    技能伤害系数: 200 * 1.1,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '20734',
    技能名称: '定波砥澜·怅归',
    统计名称: '定波砥澜',
    技能伤害系数: 500 * 1.3,
    基础伤害_基础值: 250,
    武器伤害系数: 100 / 1024,
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 75 * 1.3, 基础伤害_基础值: 100 },
      2: { 技能伤害系数: 150 * 1.3, 基础伤害_基础值: 150 },
      3: { 技能伤害系数: 300 * 1.3, 基础伤害_基础值: 200 },
      4: { 技能伤害系数: 500 * 1.3, 基础伤害_基础值: 250 },
    },
  },
  {
    技能ID: '32478',
    技能名称: '风驰',
    技能伤害系数: 540,
    基础伤害_基础值: 130,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '19819',
    技能名称: '木落雁归',
    基础伤害_基础值: 1083,
    基础伤害_浮动值: 15,
    技能伤害系数: 526.24,
    武器伤害系数: 2,
    技能增益列表: 木落雁归增益,
  },
  {
    技能ID: '32595',
    技能名称: '怒翼',
    基础伤害_基础值: 73,
    基础伤害_浮动值: 10,
    技能伤害系数: 180.57600000000002,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '19766',
    技能名称: '击水三千·一',
    统计名称: '击水三千',
    秘籍依赖技能: '击水三千',
    基础伤害_基础值: 500,
    基础伤害_浮动值: 15,
    技能伤害系数: 190.08,
    武器伤害系数: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '19767',
    技能名称: '击水三千·二',
    统计名称: '击水三千',
    秘籍依赖技能: '击水三千',
    基础伤害_基础值: 500,
    基础伤害_浮动值: 15,
    技能伤害系数: 213.84,
    武器伤害系数: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '20014',
    技能名称: '击水三千·三',
    统计名称: '击水三千',
    秘籍依赖技能: '击水三千',
    基础伤害_基础值: 500,
    基础伤害_浮动值: 15,
    技能伤害系数: 237.60000000000002,
    武器伤害系数: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '20052',
    技能名称: '浮游天地',
    基础伤害_基础值: 833,
    基础伤害_浮动值: 10,
    技能伤害系数: 140,
    武器伤害系数: 2,
    技能增益列表: 浮游天地增益,
  },
  {
    技能ID: '25273',
    技能名称: '青冥',
    基础伤害_基础值: 133,
    基础伤害_浮动值: 10,
    技能伤害系数: (130 + 16) * 1.15 * 0.7,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38725',
    技能名称: '浪斥',
    基础伤害_基础值: 130,
    基础伤害_浮动值: 5,
    技能伤害系数: 500 * 0.85 * 1.15 * 2,
    技能增益列表: 通用增益,
  },
  {
    // 29350_38675
    技能名称: '青冥(DOT)',
    统计名称: '青冥(DOT)',
    基础伤害_基础值: 50,
    DOT生效间隔: 32,
    DOT跳数: 6,
    技能伤害系数: 1650,
    伤害计算次数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '25783',
    技能名称: '木落雁归·神兵',
    基础伤害_浮动值: 2,
    技能伤害系数: 50,
    技能增益列表: 通用增益,
  },
  {
    // 19557
    技能名称: '御波驾澜(DOT)',
    统计名称: '御波驾澜(DOT)',
    基础伤害_基础值: 25,
    DOT生效间隔: 48,
    DOT跳数: 6,
    技能伤害系数: 680,
    伤害计算次数: 1,
    技能增益列表: 通用增益,
  },
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
export default 技能基础数据
