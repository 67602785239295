import { 团队增益选项数据类型, 增益选项数据类型 } from '@/@types/团队增益'
import { 技能基础数据模型, 技能增益列表类型 } from '@/@types/技能'
import { 装备增益类型 } from '@/@types/装备'
import { deepClone } from '@/工具函数/help'

/**
 * 根据当前装备的增益情况，修改技能基础增益
 */

export const 根据装备格式化技能基础数据 = (
  技能系数: 技能基础数据模型[],
  装备增益: 装备增益类型
) => {
  return 技能系数.map((item) => {
    if (item?.技能等级数据) {
      const 技能等级数据 = item?.技能等级数据
      for (const 等级 in 技能等级数据) {
        技能等级数据[等级].技能增益列表 = 获取技能增益判断(
          { ...item, 技能增益列表: 技能等级数据?.[等级].技能增益列表 },
          装备增益
        )
      }
      return { ...item, 技能增益列表: 获取技能增益判断(item, 装备增益), 技能等级数据 }
    } else {
      return { ...item, 技能增益列表: 获取技能增益判断(item, 装备增益) }
    }
  })
}

export const 获取技能增益判断 = (
  技能: 技能基础数据模型,
  装备增益?: 装备增益类型,
  团队增益?: 团队增益选项数据类型[] | false,
  团队快照计算列表?
): 技能增益列表类型[] => {
  const 格式化后增益列表 = (技能.技能增益列表 || [])?.map((增益) => {
    if (增益?.增益所在位置 === '装备' && 装备增益) {
      return { ...增益, 增益启用: 增益?.增益启用 || 判断增益是否启用(增益, 装备增益) }
    }
    if (
      增益?.增益所在位置 === '团队增益' &&
      团队增益 &&
      团队快照计算列表?.includes(增益?.依赖团队增益)
    ) {
      return 判断团队快照增益是否启用(增益, 团队增益, 增益?.依赖团队增益)
    } else {
      return 增益
    }
  })

  return 格式化后增益列表
}

export const 判断增益是否启用 = (增益: 技能增益列表类型, 装备增益: 装备增益类型) => {
  switch (增益?.增益名称) {
    case '大橙武技能增伤':
      return !!装备增益?.大橙武特效
    case '小橙武技能会心提高':
      return !!装备增益?.小橙武特效
    case '套装技能增伤':
      return !!装备增益?.套装技能
    default:
      return false
  }
}

export const 判断团队快照增益是否启用 = (
  增益: 技能增益列表类型,
  团队增益: 团队增益选项数据类型[],
  依赖团队增益
) => {
  const 找到对应团队增益 = 团队增益?.find((item) => item?.增益名称 === 依赖团队增益)
  if (找到对应团队增益 && 找到对应团队增益?.启用 && !(增益 as any)?.计算标记) {
    const 获取对应实际层数 = 找到对应团队增益?.层数
    if (获取对应实际层数) {
      const 新增益集合 = 增益?.增益集合?.map((item) => {
        return {
          ...item,
          值: item?.值 * 获取对应实际层数,
        }
      })
      return { ...增益, 增益启用: true, 增益集合: 新增益集合, 计算标记: true }
    } else {
      return { ...增益, 增益启用: true }
    }
  } else {
    return 增益
  }
}

export const 根据团队增益格式化技能基础数据 = (
  技能系数: 技能基础数据模型[],
  团队增益: 增益选项数据类型 | false,
  团队快照计算列表: string[]
) => {
  if (团队增益) {
    const 技能系数副本 = deepClone(技能系数)
    // 因为里面有function 使用JSON转化会导致该function失效
    return 技能系数副本.map((item) => {
      if (item?.技能等级数据) {
        const 技能等级数据 = item?.技能等级数据
        for (const 等级 in 技能等级数据) {
          技能等级数据[等级].技能增益列表 = 获取技能增益判断(
            { ...item, 技能增益列表: 技能等级数据?.[等级].技能增益列表 },
            undefined,
            团队增益?.团队增益,
            团队快照计算列表
          )
        }
        return {
          ...item,
          技能增益列表: 获取技能增益判断(item, undefined, 团队增益?.团队增益, 团队快照计算列表),
          技能等级数据,
        }
      } else {
        return {
          ...item,
          技能增益列表: 获取技能增益判断(item, undefined, 团队增益?.团队增益, 团队快照计算列表),
        }
      }
    })
  } else {
    return 技能系数
  }
}
