// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K2VRur3n8SK6ZXNOeQlQ {
  position: absolute;
  left: 150px;
  top: 60px;
  text-align: center;
  width: 340px;
  color: #fff;
}
.K2VRur3n8SK6ZXNOeQlQ.UhOUd0A9be2Z56tEdXo7 {
  top: 43px;
}
.tgpmciviiy8VcCw4d9xn {
  display: block;
  margin: 0 auto 8px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
}
.vwtOhbBLxGmVQiry3IpU {
  display: block;
  margin: 0 auto 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #ebebeb;
}
.ACgzswR59E6IF3pGX_a0 {
  display: block;
  margin: 0 auto 6px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #cacaca;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/标题区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,SAAA;AACJ;AAGA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADF;AAIA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFF;AAKA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHF","sourcesContent":[".titleWrap {\n  position: absolute;\n  left: 150px;\n  top: 60px;\n  text-align: center;\n  width: 340px;\n  color: #fff;\n\n  &.withDesc {\n    top: 43px;\n  }\n}\n\n.name {\n  display: block;\n  margin: 0 auto 8px;\n  font-size: 28px;\n  line-height: 28px;\n  font-weight: 500;\n}\n\n.user {\n  display: block;\n  margin: 0 auto 6px;\n  font-size: 16px;\n  line-height: 20px;\n  font-weight: 500;\n  color: rgb(235, 235, 235)\n}\n\n.desc {\n  display: block;\n  margin: 0 auto 6px;\n  font-size: 14px;\n  line-height: 24px;\n  font-weight: 400;\n  color: #cacaca\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrap": `K2VRur3n8SK6ZXNOeQlQ`,
	"withDesc": `UhOUd0A9be2Z56tEdXo7`,
	"name": `tgpmciviiy8VcCw4d9xn`,
	"user": `vwtOhbBLxGmVQiry3IpU`,
	"desc": `ACgzswR59E6IF3pGX_a0`
};
export default ___CSS_LOADER_EXPORT___;
