import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import { 按数字生成数组 } from '@/工具函数/help'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

import 无界通用增益 from '@/心法模块/统一数据/无界增益'
const {
  无界通用增伤10函数,
  无界通用增伤15函数,
  无界通用增伤20函数,
  无界通用会心15函数,
  无界通用大橙武增伤函数,
} = 无界通用增益

const 劲风簇秘章函数 = () => {
  const 数组 = 按数字生成数组(5)
  // 71172
  // 可叠加多层，最多5层
  return 数组.map((item) => {
    return {
      增益名称: `劲风簇_秘章·${item}`,
      增益所在位置: '技能',
      增益类型: '部分启用',
      增益集合: [{ 属性: 属性类型.通用增伤, 值: (21 * item) / 1024 }],
    } as 技能增益列表类型
  })
}

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 635 / 1024 }],
  },
  {
    增益名称: '无视防御',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 614 }],
  },
  // 套装双会特效
  {
    增益名称: '雷引',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 41 },
    ],
  },
  ...劲风簇秘章函数(),
  ...无界通用增伤10函数(),
  ...无界通用增伤15函数(),
  ...无界通用增伤20函数(),
  ...无界通用会心15函数(),
  ...无界通用大橙武增伤函数(),
]

export default 通用增益
