import { 技能基础数据模型 } from '@/@types/技能'
import 击水三千增益 from './技能增益/击水三千'
import 通用增益 from './技能增益/通用增益/通用'
import 对阵招式增益 from './技能增益/通用增益/对阵招式增益'
import 逐波灵游增益 from './技能增益/逐波灵游'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '102091',
    技能名称: '击水三千·悟·一',
    统计名称: '击水三千·悟',
    技能伤害系数: 268,
    武器伤害系数: 1,
    技能破招系数: 1024 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102092',
    技能名称: '击水三千·悟·二',
    统计名称: '击水三千·悟',
    技能伤害系数: 298,
    武器伤害系数: 1,
    技能破招系数: 1147 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102093',
    技能名称: '击水三千·悟·三',
    统计名称: '击水三千·悟',
    技能伤害系数: 327,
    武器伤害系数: 1,
    技能破招系数: 1249 / 1024,
    基础伤害_基础值: 1,
    技能增益列表: 击水三千增益,
  },
  {
    技能ID: '102111',
    技能名称: '澹然若海·悟',
    统计名称: '澹然若海·悟',
    技能伤害系数: 783,
    武器伤害系数: 2,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102145',
    技能名称: '澹然若海·悟·龙卷',
    统计名称: '澹然若海·悟',
    技能伤害系数: 109,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102134',
    技能名称: '振翅图南·悟',
    统计名称: '振翅图南·悟',
    技能伤害系数: 88,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102173',
    技能名称: '振翅图南·悟·额外',
    统计名称: '振翅图南·悟',
    技能伤害系数: 306,
    基础伤害_基础值: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '102161',
    技能名称: '定波砥澜·悟',
    技能伤害系数: 808,
    技能破招系数: 3103 / 1024,
    武器伤害系数: 2,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102104',
    技能名称: '逐波灵游·悟',
    技能伤害系数: 522,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: 逐波灵游增益,
  },
  {
    技能ID: '102103',
    技能名称: '溟海御波·悟·一',
    统计名称: '溟海御波·悟',
    技能伤害系数: 386,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  {
    技能ID: '102228',
    技能名称: '溟海御波·悟·二',
    统计名称: '溟海御波·悟',
    技能伤害系数: 399,
    武器伤害系数: 1,
    基础伤害_基础值: 1,
    技能增益列表: [...通用增益, ...对阵招式增益],
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

const 技能增益 = {
  通用增益,
}

export { 技能增益 }
export default 技能基础数据
