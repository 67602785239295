import { Modal, ModalProps, Radio } from 'antd'
import 所有心法数据 from '@/心法模块/心法'
import { 心法配置类型 } from '@/心法模块/interface'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { useState } from 'react'
import { 数据埋点 } from '@/工具函数/tools/log'
import './index.css'

const 当前数据 = 获取当前数据()

const 心法切换弹窗: React.FC<ModalProps> = (props) => {
  const 切换至对应心法 = (目标心法) => {
    数据埋点('切换心法')
    const 目标心法简写 = 所有心法数据?.[目标心法]?.简写
    const allPathList = location.href?.split('/')
    // allPathList[allPathList.length - 1] = `?心法=${目标心法}`
    if (目标心法简写) {
      allPathList[allPathList.length - 1] = `?xf=${目标心法简写}`
    } else {
      allPathList[allPathList.length - 1] = `?心法=${目标心法}`
    }
    const newPath = allPathList.join('/')
    if (window) {
      window.location.href = newPath
    }
  }

  const [当前平台, 设置当前平台] = useState<'旗舰' | '无界'>(
    当前数据?.心法所属端 === '无界' ? '无界' : '旗舰'
  )

  const hrefDev = location.href?.includes('localhost')
  const DEV = hrefDev

  return (
    <Modal
      title={
        <div className='school-switch-wrap'>
          <h1 className='school-switch-title'>心法切换</h1>
          <div className='school-switch-tabs'>
            <Radio.Group
              value={当前平台}
              onChange={(e) => 设置当前平台(e?.target?.value)}
              buttonStyle='solid'
            >
              <Radio.Button value={'旗舰'} key={'旗舰'}>
                旗舰
              </Radio.Button>
              <Radio.Button value={'无界'} key={'无界'}>
                无界
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      }
      {...props}
      footer={null}
      width={660}
      className='school-switch-modal-wrap'
    >
      <div className='school-switch-modal'>
        {Object.keys(所有心法数据)
          ?.filter((key) => {
            if (DEV) {
              return true
            } else {
              const 心法: 心法配置类型 = 所有心法数据[key]
              return !心法?.内部测试
            }
          })
          ?.filter((key) => {
            const 心法: 心法配置类型 = 所有心法数据[key]
            return 心法?.心法所属端 === '无界' ? 当前平台 === '无界' : 当前平台 === '旗舰'
          })
          ?.map((key) => {
            const 心法: 心法配置类型 = 所有心法数据[key]
            const 是当前心法 = 心法.名称 === 当前数据.名称

            return (
              <div
                className={`school-switch-item ${是当前心法 ? 'school-switch-item-active' : ''}`}
                key={心法?.名称}
                onClick={() => 切换至对应心法(key)}
              >
                <div className='school-switch-item-content'>
                  <img className='school-switch-item-img' src={心法?.系统配置?.心法图标} />
                  <span
                    className={`school-switch-item-text ${
                      是当前心法 ? 'school-switch-list-active' : ''
                    }`}
                  >
                    {心法?.名称?.replace('_悟', '')}
                  </span>
                </div>
                <img className='school-switch-item-img-bg' src={心法?.系统配置?.心法图标} />
                <div
                  className='school-switch-item-bg'
                  style={{ backgroundColor: 心法?.系统配置?.主题色 }}
                />
              </div>
            )
          })}
      </div>
    </Modal>
  )
}

export default 心法切换弹窗
