// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lg1e2nsl5njkJWrQ50qK {
  display: flex;
  padding-top: 12px;
}
.R4iec3BTgLmbMw0jAGIW {
  width: 1120px;
  height: 630px;
  background-size: cover;
  position: relative;
}
.tEKHCr0tfXgASIXQcdWz {
  width: 200px;
}
.MztrDkwQdVTOKUTE2HAb {
  display: flex;
  align-items: center;
}
.hiNkNQiJkhz8NIMFRsUV {
  margin-right: 8px;
}
.jcjNo3IF5y2yenMUpfGl {
  font-size: 12px;
  color: #ff0000;
  opacity: 0.7;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AAEA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAGA;EACE,YAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EACE,eAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AAJF","sourcesContent":[".content {\n  display: flex;\n  padding-top: 12px;\n}\n\n.left {\n  width: 1120px;\n  height: 630px;\n  background-size: cover;\n  position: relative;\n}\n\n.right {\n  width: 200px;\n}\n\n.titleWrap {\n  display: flex;\n  align-items: center;\n}\n\n.title {\n  margin-right: 8px;;\n}\n\n.notice {\n  font-size: 12px;\n  color: #ff0000;\n  opacity: 0.7;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `lg1e2nsl5njkJWrQ50qK`,
	"left": `R4iec3BTgLmbMw0jAGIW`,
	"right": `tEKHCr0tfXgASIXQcdWz`,
	"titleWrap": `MztrDkwQdVTOKUTE2HAb`,
	"title": `hiNkNQiJkhz8NIMFRsUV`,
	"notice": `jcjNo3IF5y2yenMUpfGl`
};
export default ___CSS_LOADER_EXPORT___;
