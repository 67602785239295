import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 369 / 1024 }],
  },
  {
    增益名称: '强膂',
    增益所在位置: '奇穴',
    是否常驻面板: true,
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.郭氏力道, 值: 102 },
      { 属性: 属性类型.郭氏无双, 值: 102 },
    ],
  },
  {
    增益名称: '灭影追风',
    增益所在位置: '技能',
    增益启用: true,
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏外功破防等级, 值: 102 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 100 },
    ],
  },
  // 由于dot吃快照，对灭进行拆分处理
  {
    增益名称: '灭影追风_双会',
    增益所在位置: '技能',
    增益启用: true,
    增益类型: '部分启用',
    增益集合: [
      { 属性: 属性类型.外功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏外功会心效果等级, 值: 100 },
    ],
  },
  {
    增益名称: '灭影追风_破防',
    增益所在位置: '技能',
    增益启用: true,
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏外功破防等级, 值: 102 }],
  },
  {
    增益名称: '流岚',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 256 }],
  },
]

export default 通用增益
