import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用'
import 贯穿增益 from './技能增益/贯穿'
import 劲风簇增益 from './技能增益/劲风簇'
import 标鹄增益 from './技能增益/标鹄'
import 饮羽簇增益 from './技能增益/饮羽簇'
import 宠物增益 from './技能增益/宠物通用'
import 风矢增益 from './技能增益/风矢'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 贯穿伤害系数 = 215 * 0.7 * 1.15 * 0.9 * 0.9 * 0.9 * 1.1
const 贯穿DOT间隔 = 8
const 贯穿跳数 = 4

const 贯穿基础伤害 = 32

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '36157',
    技能名称: '标鹄',
    技能伤害系数: 553.7664,
    基础伤害_基础值: 30,
    基础伤害_浮动值: 20,
    技能增益列表: 标鹄增益,
  },
  {
    技能ID: '35866',
    技能名称: '劲风簇',
    技能伤害系数: 154.86187499999997,
    基础伤害_基础值: 333,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 劲风簇增益,
  },
  {
    技能ID: '39092',
    技能名称: '饮羽簇',
    // 技能伤害系数: 552 * 0.9 * 0.9 * 0.95 * 0.9 * 0.95 * 1.1,
    技能伤害系数: 399.490542,
    基础伤害_基础值: 732,
    基础伤害_浮动值: 10,
    武器伤害系数: 2,
    技能增益列表: 饮羽簇增益,
  },
  {
    技能名称: '霖急簇',
    技能伤害系数: 160 * 1.1,
    基础伤害_基础值: 28,
    基础伤害_浮动值: 5,
    武器伤害系数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '35894',
    技能名称: '风矢',
    技能伤害系数: 24,
    武器伤害系数: 1,
    技能增益列表: 风矢增益,
  },
  {
    技能ID: '36111',
    技能名称: '攻击-狼',
    统计名称: '攻击',
    技能伤害系数: 289.8,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能ID: '36112',
    技能名称: '攻击-虎',
    统计名称: '攻击',
    技能伤害系数: 869.4000000000001,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能ID: '36113',
    技能名称: '攻击-鹰',
    统计名称: '攻击',
    技能伤害系数: 637.35,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能ID: '36114',
    技能名称: '攻击-熊',
    统计名称: '攻击',
    技能伤害系数: 173.25,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能ID: '36057',
    技能名称: '重击', // 野猪
    技能伤害系数: 289.8,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能ID: '36056',
    技能名称: '践踏', // 大象
    技能伤害系数: 637.35,
    基础伤害_基础值: 296,
    基础伤害_浮动值: 20,
    技能增益列表: 宠物增益,
  },
  {
    技能名称: '贯穿·1',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·2',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·3',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·4',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·5',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·6',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 6,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·8',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4 * 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·9',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 3 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·10',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5 * 2,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·12',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 4 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·15',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 5 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '贯穿·18',
    统计名称: '贯穿',
    技能伤害系数: 贯穿伤害系数,
    DOT生效间隔: 贯穿DOT间隔,
    DOT跳数: 贯穿跳数,
    基础伤害_基础值: 贯穿基础伤害,
    伤害计算次数: 6 * 3,
    技能增益列表: 贯穿增益,
  },
  {
    技能名称: '白虹贯日',
    技能伤害系数: 7.25,
    基础伤害_基础值: 400,
    基础伤害_浮动值: 200,
    武器伤害系数: 2,
    技能增益列表: 通用增益,
  },
  {
    // 实测没有武伤
    技能ID: '36453',
    技能名称: '朝仪万汇',
    秘籍依赖技能: '劲风簇',
    技能伤害系数: 368.94000000000005 * 1.2,
    基础伤害_基础值: 37,
    基础伤害_浮动值: 5,
    技能增益列表: 劲风簇增益,
  },
  {
    技能ID: '36177',
    技能名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.3 - 1)),
    武器伤害系数: 0,
    伤害计算次数: 1,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '36579',
    技能名称: '劲风簇·神兵',
    技能伤害系数: 60,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  // CW期间饮羽命中后施加的额外伤害
  {
    技能ID: '36580',
    技能名称: '月弦激星',
    技能伤害系数: 468.0,
    基础伤害_基础值: 20,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  贯穿增益,
  劲风簇增益,
  标鹄增益,
  饮羽簇增益,
  宠物增益,
  风矢增益,
}

export { 技能增益 }
