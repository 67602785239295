import { 技能基础数据模型 } from '@/@types/技能'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 判官笔法增益 from './技能增益/判官笔法'
import 快雪时晴增益 from './技能增益/快雪时晴'
import 阳明指增益 from './技能增益/阳明指'
import 芙蓉并蒂增益 from './技能增益/芙蓉并蒂'
import 钟林毓秀增益 from './技能增益/钟林毓秀'
import 故幽增益 from './技能增益/故幽'
import 玉石俱焚增益 from './技能增益/玉石俱焚'
import 真实伤害系数 from '../../统一数据/真实伤害系数'
import 心法通用系数 from '../../统一数据/心法通用系数'

const 兰摧玉折基础数据: Partial<技能基础数据模型> = {
  统计名称: '兰摧玉折(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 30,
  技能伤害系数: 729.2525178318296,
  技能增益列表: 通用增益,
}

const 钟林毓秀基础数据: Partial<技能基础数据模型> = {
  统计名称: '钟林毓秀(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 38,
  技能伤害系数: 478.6728898480225,
  技能增益列表: 钟林毓秀增益,
}

const 商阳指基础数据: Partial<技能基础数据模型> = {
  统计名称: '商阳指(DOT)',
  DOT生效间隔: 48,
  DOT跳数: 6, // 基础6跳，秘籍+1跳，系数按6算
  基础伤害_基础值: 50,
  技能伤害系数: 440.3790586601807,
  技能增益列表: 通用增益,
}

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能ID: '16',
    技能名称: '判官笔法',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 判官笔法增益,
  },
  {
    技能ID: '32467',
    技能名称: '破',
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(-560523.640832),
    技能增益列表: 通用增益,
    技能等级数据: {
      1: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      2: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      3: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      4: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      5: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      6: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      7: { 技能伤害系数: 获取破招实际系数(-560523.640832) },
      8: { 技能伤害系数: 获取破招实际系数(415581.07750400016) },
    },
  },
  {
    技能ID: '182',
    技能名称: '玉石俱焚',
    技能伤害系数: 73.6,
    基础伤害_基础值: 81,
    基础伤害_浮动值: 10,
    技能增益列表: 玉石俱焚增益,
  },
  {
    技能ID: '186',
    技能名称: '芙蓉并蒂',
    技能伤害系数: 261.28,
    基础伤害_基础值: 76,
    基础伤害_浮动值: 5,
    技能增益列表: [...芙蓉并蒂增益, ...故幽增益],
  },
  {
    技能ID: '6693',
    技能名称: '商阳指',
    技能伤害系数: 73.6,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '14941',
    技能名称: '阳明指',
    技能伤害系数: 339.405066,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 阳明指增益,
  },
  {
    技能ID: '25768',
    技能名称: '兰摧玉折·神兵',
    技能伤害系数: 100,
    基础伤害_基础值: 0,
    基础伤害_浮动值: 2,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '32501',
    技能名称: '折花',
    统计名称: '折花',
    技能伤害系数: 304 * 1.15,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 通用增益,
    技能等级数据: {
      4: { 技能伤害系数: 334 },
    },
  },
  {
    技能ID: '33222',
    技能名称: '快雪时晴·雪弃',
    统计名称: '快雪时晴',
    秘籍依赖技能: '快雪时晴',
    技能伤害系数: 123.511311 * 1.2,
    基础伤害_基础值: 65,
    基础伤害_浮动值: 10,
    技能增益列表: 快雪时晴增益,
  },
  {
    技能ID: '6233',
    技能名称: '快雪时晴',
    统计名称: '快雪时晴',
    秘籍依赖技能: '快雪时晴',
    技能伤害系数: 123.511311 * 1.2,
    基础伤害_基础值: 65,
    基础伤害_浮动值: 10,
    技能增益列表: 快雪时晴增益,
  },
  {
    技能名称: '快雪时晴(DOT)', // 快雪dot dotID 24158 触发ID 32481
    统计名称: '快雪时晴(DOT)',
    DOT生效间隔: 64,
    DOT跳数: 7,
    技能伤害系数: 50.748882291843756,
    基础伤害_基础值: 38,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '快雪时晴(DOT)·芙蓉', // 快雪dot dotID 24158 触发ID 32409
    统计名称: '快雪时晴(DOT)',
    DOT生效间隔: 64,
    DOT跳数: 7,
    技能伤害系数: 50.748882291843756,
    基础伤害_基础值: 38,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能ID: '37270',
    技能名称: '墨海·一', // 37270/1
    统计名称: '墨海',
    技能伤害系数: 1851.3000000000004,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37270',
    技能名称: '墨海·二', // 37270/3
    统计名称: '墨海',
    技能伤害系数: 555.3900000000001,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37270',
    技能名称: '临源·一', // 37270/2
    统计名称: '临源',
    技能伤害系数: 2178.0,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37270',
    技能名称: '临源·二', // 37270/4
    统计名称: '临源',
    技能伤害系数: 2178.0,
    基础伤害_基础值: 34,
    基础伤害_浮动值: 5,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '38955',
    技能名称: '焚玉', // 38955
    技能伤害系数: 300,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '37525',
    技能名称: '钟灵', // 37525
    技能伤害系数: 328,
    基础伤害_基础值: 121,
    基础伤害_浮动值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '30648',
    技能名称: '丹青', // 30648
    技能伤害系数: 470.4,
    基础伤害_基础值: 155,
    基础伤害_浮动值: 28,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '32629',
    技能名称: '雨墨', // 32629
    技能伤害系数: 100.0,
    基础伤害_基础值: 400,
    基础伤害_浮动值: 15,
    技能增益列表: 通用增益,
  },
  {
    技能ID: '26696',
    技能名称: '碎玉', // 26696
    技能伤害系数: 231.00000000000003,
    基础伤害_基础值: 15,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
  },
  /**
   * 兰摧玉折
   */
  {
    技能名称: '兰摧玉折(DOT)', // 普通 兰摧玉折 dotID 711 触发ID 18730
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·阳明', // 阳明 兰摧玉折 dotID 711 触发ID 13848
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·乱撒', // 乱撒阳明 兰摧玉折 dotID 711 触发ID 39906
    ...兰摧玉折基础数据,
    技能伤害系数: 875.1030213981954,
  },
  {
    技能名称: '兰摧玉折(DOT)·吞海', // 711_18730_601
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·阳明吞海', // 711_13848_601
    ...兰摧玉折基础数据,
  },
  {
    技能名称: '兰摧玉折(DOT)·乱撒吞海', // 711_39906_601
    ...兰摧玉折基础数据,
    技能伤害系数: 875.1030213981954,
  },
  {
    技能名称: '兰摧玉折(DOT)·玉石',
    ...兰摧玉折基础数据,
  },
  /**
   * 钟林毓秀
   */
  {
    技能名称: '钟林毓秀(DOT)', // 普通 钟林毓秀 dotID 714 触发ID 285
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·阳明', // 阳明 钟林毓秀 dotID 714 触发ID 13847
    ...钟林毓秀基础数据,
    技能伤害系数: 478.6728898480225,
  },
  {
    技能名称: '钟林毓秀(DOT)·乱撒', // 乱撒阳明 钟林毓秀 dotID 714 触发ID 39907
    ...钟林毓秀基础数据,
    技能伤害系数: 574.407467817627,
  },
  {
    技能名称: '钟林毓秀(DOT)·吞海', // 714_285_601
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·阳明吞海', // 714_3847_601
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·乱撒吞海', // 714_39907_601
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·玉石',
    ...钟林毓秀基础数据,
  },
  {
    技能名称: '钟林毓秀(DOT)·橙武', // 乱撒阳明 钟林毓秀 dotID 714 触发ID 3086
    ...钟林毓秀基础数据,
  },
  /**
   * 商阳指
   */
  {
    技能名称: '商阳指(DOT)', // 普通 商阳指 dotID 666 触发ID 180
    ...商阳指基础数据,
  },
  {
    技能名称: '商阳指(DOT)·阳明', // 乱撒阳明 商阳指 dotID 666 触发ID 13849
    ...商阳指基础数据,
  },
  {
    技能名称: '商阳指(DOT)·吞海', // 666_180_601
    ...商阳指基础数据,
  },
  {
    技能名称: '商阳指(DOT)·玉石', // 666_6126
    ...商阳指基础数据,
  },
  /**
   * 芙蓉相关
   */
  {
    技能名称: '兰摧玉折(DOT)·芙蓉', // 刷新 兰摧玉折 dotID 711 触发ID 6136
    ...兰摧玉折基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '兰摧玉折(DOT)·芙蓉玉石',
    ...兰摧玉折基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '钟林毓秀(DOT)·芙蓉', // 刷新 钟林毓秀 dotID 714 触发ID 6135
    ...钟林毓秀基础数据,
    技能增益列表: [...钟林毓秀增益, ...故幽增益],
  },
  {
    技能名称: '钟林毓秀(DOT)·芙蓉玉石',
    ...钟林毓秀基础数据,
    技能增益列表: [...钟林毓秀增益, ...故幽增益],
  },
  {
    技能名称: '商阳指(DOT)·芙蓉', // 刷新 商阳指 dotID 666 触发ID 6134
    ...商阳指基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  {
    技能名称: '商阳指(DOT)·芙蓉玉石',
    ...商阳指基础数据,
    技能增益列表: [...通用增益, ...故幽增益],
  },
  // --------------
  ...真实伤害系数,
  ...心法通用系数(通用增益),
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  判官笔法增益,
  快雪时晴增益,
  阳明指增益,
  芙蓉并蒂增益,
  钟林毓秀增益,
  故幽增益,
}

export { 技能增益 }
