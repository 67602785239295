import { 循环技能详情, 技能增益列表数据 } from '@/@types/循环'

const 替换非满豆两仪数量 = 6
const 替换四象数量 = 4
/**
 * 替换逻辑
 * 1、替换所有的非满豆两仪 => 满豆两仪（最多替换6个）
 * 2、替换4个四象 => 满豆两仪
 */
export const 气纯阵眼动态函数 = (数据: 循环技能详情[]) => {
  const 新技能详情: 循环技能详情[] = []
  // 最多转换六个
  let 两仪满豆转换次数 = 0
  let 两仪满豆破招转换次数 = 0

  数据.forEach((技能详情) => {
    // 处理破招
    if (技能详情?.技能名称 === '破' && (!技能详情?.技能等级 || 技能详情?.技能等级 < 3)) {
      if (技能详情?.技能数量 <= 替换非满豆两仪数量 + 2) {
        if (两仪满豆破招转换次数 < 替换非满豆两仪数量) {
          新技能详情.push({
            ...技能详情,
            技能名称: '破',
            技能等级: 3,
          })
          两仪满豆破招转换次数 += 技能详情?.技能数量
        } else {
          新技能详情.push(技能详情)
        }
      } else {
        新技能详情.push(技能详情)
      }
    } else if (技能详情?.技能名称?.includes('两仪化形') && 技能详情?.技能名称 !== '两仪化形·十') {
      if (技能详情?.技能数量 <= 替换非满豆两仪数量 + 2) {
        if (两仪满豆转换次数 < 替换非满豆两仪数量) {
          新技能详情.push({
            ...技能详情,
            技能名称: '两仪化形·十',
          })
          两仪满豆转换次数 += 技能详情?.技能数量
        } else {
          新技能详情.push(技能详情)
        }
      } else {
        新技能详情.push(技能详情)
      }
    } else if (技能详情?.技能名称 === '四象轮回') {
      // 计算四象替换逻辑
      const { 四象替换次数, 新两仪技能增益列表, 新四象技能增益列表 } = 计算四象替换逻辑(技能详情)
      // 重新补充四象
      新技能详情.push({
        ...技能详情,
        技能数量: Math.max(技能详情?.技能数量 - 四象替换次数, 0),
        技能增益列表: 新四象技能增益列表,
      })
      // 重新补充两仪
      新技能详情.push({
        ...技能详情,
        技能名称: '两仪化形·十',
        技能增益列表: 替换跬步(新两仪技能增益列表),
        技能数量: 四象替换次数,
      })
      // 新增两仪破招
      新技能详情.push({
        ...技能详情,
        技能名称: '破',
        技能增益列表: 替换跬步(新两仪技能增益列表),
        技能数量: 四象替换次数,
        技能等级: 3,
      })
    } else {
      新技能详情.push(技能详情)
    }
  })

  return 新技能详情
}

const 计算四象替换逻辑 = (技能详情) => {
  let 四象替换次数 = 0
  const 新两仪技能增益列表: 技能增益列表数据[] = []
  const 新四象技能增益列表: 技能增益列表数据[] = []
  技能详情?.技能增益列表?.forEach((增益) => {
    if (四象替换次数 < 替换四象数量) {
      const 差值 = 替换四象数量 - 四象替换次数
      const 减少后数量 = 增益?.增益技能数 - 差值
      if (增益?.增益技能数 > 差值) {
        const 新四象增益数据 = {
          ...增益,
          增益技能数: 减少后数量,
        }
        新四象技能增益列表.push(新四象增益数据)
        新两仪技能增益列表.push({
          ...增益,
          增益技能数: 差值,
        })
        四象替换次数 += 差值
      } else {
        新两仪技能增益列表.push(增益)
        四象替换次数 += 增益?.增益技能数
      }
    } else {
      新四象技能增益列表.push(增益)
    }
  })
  return { 四象替换次数, 新两仪技能增益列表, 新四象技能增益列表 }
}

const 替换跬步 = (数据: 技能增益列表数据[]) => {
  return 数据?.map((item) => {
    const 名称 = item?.增益名称?.replaceAll('跬步四象', '跬步两仪')
    return {
      ...item,
      增益名称: 名称,
    }
  })
}
