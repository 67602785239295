import { 属性类型 } from '@/@types/属性'
import { 技能增益列表类型 } from '@/@types/技能'
import 获取快照增益 from '@/心法模块/统一数据/快照增益'

const 通用增益: 技能增益列表类型[] = [
  ...获取快照增益(),
  {
    增益名称: '非侠',
    增益所在位置: '职业',
    增益启用: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.非侠增伤, 值: 266 / 1024 }],
  },
  // 套装双会特效
  {
    增益名称: '星悬',
    增益所在位置: '装备',
    增益类型: '部分启用',
    快照类型: '套装会心会效',
    依赖装备增益: '套装会心会效',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.04 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 41 },
    ],
  },
  {
    // 34680
    增益名称: '度冥',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏无双, 值: 72 * 3 }],
  },
  // 由于旧JCL无度冥生成，导致暂时用全局度冥实现，体服后修改为快照度冥
  // {
  //   // 29835_1
  //   增益名称: '度冥·1',
  //   增益所在位置: '奇穴',
  //   依赖奇穴: '度冥',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏无双, 值: 72 }],
  // },
  // {
  //   // 29835_2
  //   增益名称: '度冥·2',
  //   增益所在位置: '奇穴',
  //   依赖奇穴: '度冥',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏无双, 值: 72 * 2 }],
  // },
  // {
  //   // 29835_3
  //   增益名称: '度冥·3',
  //   增益所在位置: '奇穴',
  //   依赖奇穴: '度冥',
  //   增益类型: '部分启用',
  //   增益集合: [{ 属性: 属性类型.郭氏无双, 值: 72 * 3 }],
  // },
  {
    // 18176
    增益名称: '祝祷',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    // 增益类型: '全局启用', // 等考拉支持了再删
    增益集合: [{ 属性: 属性类型.通用增伤, 值: 102 / 1024 }],
  },
  {
    增益名称: '荧入白',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    增益集合: [{ 属性: 属性类型.郭氏全无视防御, 值: 512 }],
  },
  {
    增益名称: '鬼遁',
    增益所在位置: '奇穴',
    增益类型: '部分启用',
    // 增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏内功基础攻击, 值: 308 }],
  },
  {
    // 18002
    增益名称: '枭神',
    增益所在位置: '奇穴',
    // 增益类型: '部分启用',
    增益类型: '全局启用',
    增益集合: [
      { 属性: 属性类型.内功会心百分比, 值: 0.1 },
      { 属性: 属性类型.郭氏内功会心效果等级, 值: 102 },
    ],
  },
  // {
  //   增益名称: '枭神_快照',
  //   增益所在位置: '技能',
  //   // 增益类型: '部分启用',
  //   增益类型: '部分启用',
  //   增益集合: [
  //     { 属性: 属性类型.内功会心百分比, 值: 0.1 },
  //     { 属性: 属性类型.郭氏内功会心效果等级, 值: 100 },
  //   ],
  // },
  {
    增益名称: '神元',
    增益所在位置: '奇穴',
    是否常驻面板: true,
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.郭氏元气, 值: 102 }],
  },
]

export default 通用增益
