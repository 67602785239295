// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mIEkHlxNnux3g2QMgURD {
  position: absolute;
  left: 25px;
  top: 50px;
  text-align: center;
  width: 100px;
}
.WHlw9__wflJmu5LOysi8 {
  display: block;
  margin: 0 auto 5px;
  width: 48px;
  height: 48px;
}
.WYZIQvZtupYgtntgbNtB {
  font-size: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/功能模块/基础设置/属性录入/配装器/功能组件/配装导出/图标区/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,kBAAA;EACA,YAAA;AACF;AAEA;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAAF;AAGA;EACE,eAAA;EACA,gBAAA;AADF","sourcesContent":[".logoWrap {\n  position: absolute;\n  left: 25px;\n  top: 50px;\n  text-align: center;\n  width: 100px;\n}\n\n.logo {\n  display: block;\n  margin: 0 auto 5px;\n  width: 48px;\n  height: 48px;\n}\n\n.score {\n  font-size: 16px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoWrap": `mIEkHlxNnux3g2QMgURD`,
	"logo": `WHlw9__wflJmu5LOysi8`,
	"score": `WYZIQvZtupYgtntgbNtB`
};
export default ___CSS_LOADER_EXPORT___;
